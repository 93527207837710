import moment from 'moment';
// import _truncate from 'lodash/truncate';

// Global filters available to all components
const filters = {
    // format to Jan 23
    formatMonthDay(date) {
        return moment(date).format('MMM D');
    },
    // format to Jan 23, 2021
    formatMonthDayYear(date) {
        return moment(date).format('MMM D, YYYY');
    },
    capitalizeText(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    truncate(string, length) {
        let truncatedString = string;
        if (string.length > length) {
            truncatedString = string.slice(0, length);
        }
        return truncatedString;
    },
    /* truncate(string, options) {
        let opts = options;
        if (typeof opts === 'number') {
            opts = { length: options };
        }
        if (opts && !opts.separator) {
            opts.separator = /,?\.* +/; // Separate based on spaces
        }
        return _truncate(string, opts);
    }, */
};

export default filters;
