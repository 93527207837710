<template>
    <aside class="menu primary-menu" role="navigation" aria-label="Launch Main Menu">
        <ul class="menu-list has-text-centered is-fullwidth">
            <li>
                <router-link :to="{name: 'landing-view'}">
                    <button role="link" aria-label="Home - View all Engagements">
                        <span class="icon is-extra-large">
                            <mdicon aria-hidden name="home" title="Navigate Home - View all Engagements" />
                        </span>
                    </button>
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'info-view'}">
                    <button role="link" aria-label="Navigate to Information">
                        <span class="icon is-extra-large">
                            <mdicon aria-hidden name="help-circle" title="Information" />
                        </span>
                    </button>
                </router-link>
            </li>
        </ul>
    </aside>
</template>

<script>
import { computed, toRefs } from 'vue';

export default {
    name: 'launch-menu',
    props: {
        userName: {
            default: () => '',
            type: String,
        },
    },
    setup(props) {
        const { userName } = toRefs(props);

        const userFirstName = computed(() => userName.value?.split(' ')[0]);

        return {
            userFirstName,
        };
    },
};
</script>
<style lang="scss" scoped>
@import "../styles/constants.scss";
.primary-menu {
    height: auto;
    .menu-list {
        a {
            padding: 0 0;
            button {
                border: none;
                color: $cardBackground;
                cursor: pointer;
                width: 100%;
                padding: 1em 0;
            }
            &.router-link-exact-active {
                background-color: $mitreLightSilver;
                .icon {
                    color: $mitreDarkNavy;
                }
            }
        }
        a:hover {
            .icon {
                color: $mitreDarkNavy;
            }
        }
    }
}
.menu, button {
    background-color: $mitreSilver;
    color: $contentBackground;
}
li {
    border-bottom: 1px solid $mitreLightSilver;
}

@include tablet {
    .primary-menu {
        height: 100vh;            // full viewport height
    }
}
</style>
