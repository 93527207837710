import { createRouter, createWebHistory } from 'vue-router';
import { LoginCallback, navigationGuard } from '@okta/okta-vue';
import landingView from '../views/LandingView.vue';
import infoView from '../views/InfoView.vue';

// Simple 404 fallback component
// TODO make real 404 page
const NotFound = {
    template: '<div><h2>Oops, it looks like the page you\'re looking for doesn\'t exist.</h2></div>',
};

const routes = [
    {
        path: '/',
        name: 'landing-view',
        component: landingView,
        meta: {
            requiresAuth: true,
            title: 'Engagements - MITRE | Launch',
        },
    },
    {
        path: '/info',
        name: 'info-view',
        component: infoView,
        meta: {
            requiresAuth: true,
            title: 'Info - MITRE | Launch',
        },
    },
    {
        path: '/login/callback',
        component: LoginCallback,
    },
    {
        path: '/:customCatchAll(.*)*',
        name: 'not-found',
        component: NotFound,
    },
];

console.log('***INDEX app base', process.env.BASE_URL);
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Due to navigation guards mixin issue in vue-router-next, navigation guard logic need to be added manually
router.beforeEach(navigationGuard);

// This callback runs after every route change and updates the page title and corresponding browser history
// such that titles more accurately represent the SPA pages that were visited
// https://github.com/vuejs/vue-router/issues/914
router.afterEach((to) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta?.title);
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
});

export default router;
