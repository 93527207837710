import deploy from './deploy';

const initEnvironment = function initEnvironment() {
    // default to production
    const envConfig = {
        mitreInfoSecureUrl: 'https://info.mitre.org',
        // mulesoftUrl: 'https://api.mitre.org',
        mulesoftSalesforceUrl: 'https://mulesoftsrv1.mitre.org:4447',
        mulesoftProductsUrl: 'https://mulesoftsrv1.mitre.org:4448',
        fakeProductsUrl: '/products.json',
        fakeProjectUrl: '/portal.json',
        environment: 'production',
        oktaIssuer: 'https://mitre-identity.okta.com/',
        oktaRedirectUri: 'https://launch.mitre.org/login/callback',
        oktaClientId: '0oa7zmx2r2u3hPYW8297',
    };

    if (deploy.deployEnvironment === '@@deploymentEnvironment@@' && window.location.hostname === 'localhost') {
        console.log('Deploy environment is not set! Don\'t spend another hour trying to figure out where your CORS issues are coming from');
        debugger; // eslint-disable-line no-debugger
    } else if (deploy.deployEnvironment === 'local') {
        envConfig.mitreInfoSecureUrl = ''; // relative path so webpack proxy kicks in
        envConfig.mulesoftSalesforceUrl = 'https://mulesoftdev1.mitre.org:4447';
        envConfig.mulesoftProductsUrl = 'https://mulesoftdev1.mitre.org:4448';
        envConfig.fakeProductsUrl = '/products.json';
        envConfig.fakeProjectsUrl = '/portal.json';
        envConfig.oktaIssuer = 'https://mitre-dev.oktapreview.com';
        envConfig.oktaRedirectUri = 'http://localhost:8080/login/callback';
        envConfig.oktaClientId = '0oayieak7mBwzupxM0h7';
        envConfig.environment = 'local';
    } else if (process.env.NODE_ENV === 'development' || deploy.deployEnvironment === 'development') {
        envConfig.mitreInfoSecureUrl = 'https://info-d.mitre.org';
        // envConfig.mulesoftUrl = 'https://api-d.mitre.org';
        envConfig.mulesoftSalesforceUrl = 'https://mulesoftdev1.mitre.org:4447';
        envConfig.mulesoftProductsUrl = 'https://mulesoftdev1.mitre.org:4448';
        envConfig.fakeProductsUrl = '/products.json';
        envConfig.fakeProjectsUrl = '/portal.json';
        envConfig.oktaIssuer = 'https://mitre-dev.oktapreview.com';
        envConfig.oktaRedirectUri = 'https://launchdev1.mitre.org/login/callback'; // new VM
        envConfig.oktaClientId = '0oayieak7mBwzupxM0h7';
        envConfig.environment = 'development';
    } else if (process.env.NODE_ENV === 'integration' || deploy.deployEnvironment === 'integration') {
        envConfig.mitreInfoSecureUrl = 'https://info-i.mitre.org';
        // envConfig.mulesoftUrl = 'https://api-i.mitre.org';
        envConfig.mulesoftSalesforceUrl = 'https://mulesoftint1.mitre.org:4447';
        envConfig.mulesoftProductsUrl = 'https://mulesoftint1.mitre.org:4448';
        envConfig.fakeProductsUrl = '/products.json';
        envConfig.fakeProjectsUrl = '/portal.json';
        envConfig.oktaIssuer = 'https://mitre-int.oktapreview.com/';
        envConfig.oktaRedirectUri = 'https://launchint1.mitre.org/login/callback';
        envConfig.oktaClientId = '0oa1cvqxqusnhWPPh0h8';
        envConfig.environment = 'integration';
    }

    return envConfig;
};

export default initEnvironment;
