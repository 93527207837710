<template>
    <main class="main-content mx-4" role="main">
        <div class="mb-3">
            <h2 class="title is-size-3 mb-6">
                Help
            </h2>
            <h3 class="subtitle is-size-4 mb-0">
                Overview
            </h3>
            <hr class="mt-1 mb-5">

            <div class="block">
                <h4 class="is-size-5">
                    <strong>What is MITRE Launch?</strong>
                </h4>
                <p class="block">
                    MITRE Launch is the access point for external MITRE Partners to view their Engagements and to access the
                    tools needed for collaboration with MITRE teams.
                </p>
            </div>
            <div class="block">
                <h4 class="is-size-5">
                    <strong>What are Engagement Workspaces?</strong>
                </h4>
                <p class="block">
                    Engagement Workspaces are virtual collaboration areas using tools such as Box, Microsoft Teams-External,
                    Slack and/or Websites. MITRE Partners are granted access to these tools by a MITRE Point of Contact POC).
                </p>
            </div>

            <h3 class="subtitle is-size-4 mb-0 mt-6">
                Frequently Asked Questions
            </h3>
            <hr class="mt-1 mb-5">

            <div class="block">
                <h4 class="is-size-5">
                    <strong>MITRE Launch says I have no Engagements. What does this mean?</strong>
                </h4>
                <p class="block">
                    If you are seeing a message stating you have no engagements, please try refreshing your connection. If
                    you still see no Engagements, it's possible a MITRE POC has not assigned you access to any Engagements or
                    your access may have been removed. Please reach out to your MITRE POC if you believe you are receiving this
                    message in error.
                </p>
            </div>
            <div class="block">
                <h4 class="is-size-5">
                    <strong>Who is my MITRE Engagement point of contact (POC)?</strong>
                </h4>
                <p class="block">
                    Your MITRE POC is the MITRE employee who extended the invitation for collaboration.
                </p>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: 'info-view',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h4 {
    margin-bottom: .8rem;
}
.main-content {
    height: 100vh;      // full viewport height
}
</style>
