<template>
    <main class="main-content" role="main">
        <div class="mb-6 mx-4">
            <h2 class="title is-size-3">
                Welcome
            </h2>
            <p>
                MITRE Launch is the access point for external MITRE Partners to view their Engagements and to access the tools
                needed for collaboration with MITRE teams.
            </p>
        </div>

        <!--configured engagements for authenticated user-->
        <div v-if="isEngagements" class="engagementsContainer">
            <h3 class="is-size-5 mb-4">
                My Engagements <span v-if="engagements.length">({{ engagements.length }})</span>
                <button type="link" class="button is-ghost is-pulled-right" v-on:click="toggleAllAccordions">
                    <span v-if="!isAllAccordionsOpen">Open All</span>
                    <span v-else>Close</span>
                </button>
            </h3>
            <ul>
                <li v-for="engagement in engagements" :key="engagement.name">
                    <engagement-item :engagement="engagement" :first-engagement-id="firstEngagementId" :is-all-accordions-open="isAllAccordionsOpen" />
                </li>
            </ul>
        </div>
        <!--empty message = no configured engagements for this user-->
        <div v-if="!isEngagements">
            <h3 class="is-size-4 mb-0 mt-6">
                You have no Engagements to display
            </h3>
            <hr class="mt-1 mb-5">
            <p>
                You do not currently have access to any Engagement Workspaces. Please contact your MITRE point of contact for help, or email our support team at <a href="mailto:mpnsupport@mitre.org">mpnsupport@mitre.org</a>
            </p>
        </div>
    </main>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import engagementItem from '../components/engagement/engagement-item.vue';

export default {
    name: 'landing-landing',
    components: {
        engagementItem,
    },
    props: {
        authenticatedUser: {
            default: () => ({}),
            type: Object,
        },
        engagements: {
            default: () => ([]),
            type: Array,
        },
    },
    setup(props) {
        const { authenticatedUser, engagements } = toRefs(props);

        const isEngagements = computed(() => engagements.value?.length);
        const userEmail = computed(() => authenticatedUser.value?.email);
        const firstEngagementId = computed(() => engagements.value?.[0]?.number);

        const isAllAccordionsOpen = ref(false);
        const toggleAllAccordions = () => {
            isAllAccordionsOpen.value = !isAllAccordionsOpen.value;
        };

        return {
            isEngagements,
            userEmail,
            firstEngagementId,
            toggleAllAccordions,
            isAllAccordionsOpen,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-content {
    margin-right: 2rem;
}
.engagementsContainer {
    background-color: $mitreLightSilver;
    padding: 1rem 1rem;
}
// toggle hidden tool area
ul {
    padding-left: 0;
}
ul:last-of-type {
    margin-bottom: 0;
}
li:last-of-type {
    margin-bottom: 0;
}

// larger browser width breakpoints
@include widescreen {
    .main-content {
        margin-right: 0;
    }
}
</style>
