import { createApp } from 'vue';
import { OktaAuth } from '@okta/okta-auth-js';
import OktaVue from '@okta/okta-vue';
import mdiVue from 'mdi-vue/v3';
import {
    mdiFolderOpen,
    mdiHelpCircle,
    // mdiSlack,
    mdiCloudOutline,
    mdiWeb,
    mdiTools,
    mdiOpenInNew,
    mdiArrowRightThin,
    mdiGoogleClassroom,
    mdiCodeBraces,
    mdiHome,
    mdiChevronUp,
    mdiChevronDown,
    mdiCloud,
} from '@mdi/js';
import App from './App.vue';
import router from './router';
import globalFilters from './lib/filters';
import './styles/launch.scss';
import initEnvironment from './config/config';

const envConfig = initEnvironment();
// make envConfig variables globally available
window.envConfig = envConfig;

// static path to the root directory where your app is located = /taskmatch-vue
// corresponds to publicPath in vue.config.js, passed at build to process.env
// https://cli.vuejs.org/guide/mode-and-env.html#using-env-variables-in-client-side-code
const APP_ROOT = process.env.BASE_URL;
console.log('MAIN APP Root', APP_ROOT, process.env.BASE_URL);

/* Start Okta Config with appropriate environment variables */
const oktaConfig = {
    clientId: window.envConfig.oktaClientId,
    issuer: window.envConfig.oktaIssuer,
    redirectUri: window.envConfig.oktaRedirectUri,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
};
console.log('MAIN Okta config', oktaConfig);
const oktaAuth = new OktaAuth(oktaConfig);

const app = createApp(App)
    .use(router)
    .use(OktaVue, { oktaAuth });

oktaAuth.authStateManager.subscribe((authState) => {
    // handle the latest evaluated authState, like integrate with client framework's state management store
    console.log('MAIN authState change in authStateManager.subscribe - Now', { authState, now: Date() });
});
// Triggered when a token has been renewed
oktaAuth.tokenManager.on('renewed', (key, newToken, oldToken) => {
    console.log('MAIN - Now', { now: Date() });
    console.log('Token with key', key, 'has been renewed');
    console.log('Old token:', oldToken);
    console.log('New token:', newToken);
});
// Triggered when a token has expired
oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
    console.log('MAIN - Now', { now: Date() });
    console.log('Token with key', key, ' has expired:');
    console.log(expiredToken);
});

oktaAuth.authStateManager.updateAuthState();

// Global icons
app.use(mdiVue, {
    icons: {
        mdiFolderOpen,
        mdiHelpCircle,
        // mdiSlack,
        mdiCloudOutline,
        mdiWeb,
        mdiTools,
        mdiOpenInNew,
        mdiArrowRightThin,
        mdiGoogleClassroom,
        mdiCodeBraces,
        mdiHome,
        mdiChevronUp,
        mdiChevronDown,
        mdiCloud,
    },
});

// Include global filter functions
app.config.globalProperties.$filters = globalFilters;

// enable dev tools outside of production
if (window.envConfig.environment !== 'production') {
    app.config.performance = true;
}

app.mount('#app');
