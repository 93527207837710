<template>
    <header id="mpnHeaderContainer" ref="mpnHeaderContainer" />
</template>

<script>
export default {
    name: 'mpn-header',
    mounted() {
        this.configureMPNHeader();
    },
    methods: {
        configureMPNHeader() {
            // create script tag in javascript like this:
            // https://handshake.mitre.org/pages/view/11812292/using-the-mpn-header
            // <script src="https://mpndev.mitre.org/static/header/mpn_header.js" data-name="MITRE User" data-email="muser@mitre.org">

            // configure element and attributes
            const mpnHeaderScript = document.createElement('script');
            const cacheBust = new Date().getTime();
            const dataName = document.createAttribute('data-name');
            const dataEmail = document.createAttribute('data-email');

            // configure values
            mpnHeaderScript.type = 'text/javascript';
            mpnHeaderScript.src = `https://mpn.mitre.org/static/header/mpn_header.js?cacheBust=${cacheBust}`;
            // TODO update to use real user info
            // If email not passed then search not shown, if name not passed then Welcome not shown
            dataName.value = '';
            mpnHeaderScript.setAttributeNode(dataName);
            dataEmail.value = '';
            mpnHeaderScript.setAttributeNode(dataEmail);

            // add to DOM
            this.$refs.mpnHeaderContainer.appendChild(mpnHeaderScript);
        },
    },
};
</script>
