<template>
    <header id="app-header">
        <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
            <h2 class="navbar-brand title">
                <router-link :to="{name: 'landing-view'}" class="navbar-item mt-2">
                    <img src="../assets/logo_launch_mitre.svg" alt="Launch Home">
                </router-link>
            </h2>
            <div class="navbar-end">
                <span v-if="userFirstName" class="navbar-item is-6">Hi, {{ userFirstName }}</span>
                <span v-else class="navbar-item is-6">Please log in</span>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    name: 'launch-header',
    props: {
        authenticatedUser: {
            default: () => ({}),
            type: Object,
        },
    },
    computed: {
        userFirstName() {
            return this.authenticatedUser.firstName;
        },
    },
};
</script>
<style scoped lang="scss">
@import "./../styles/constants.scss";
.title:not(:last-child) {
    margin-bottom: 0.5rem;
}
.navbar.is-dark {
    background-color: $mitreDarkNavy;
    color: $cardBackground;
    .navbar-link.is-active, .navbar-brand .navbar-link:focus, .navbar-brand .navbar-link:hover, .navbar-brand>a.navbar-item.is-active, .navbar-brand>a.navbar-item:focus, .navbar-brand>a.navbar-item:hover {
        background-color: $mitreDarkNavy;
        color: $mitreLightBlue;
    }
    h2 {
        letter-spacing: 2px;
    }
    .navbar-item {
        color: $cardBackground;
    }
    img {
        height: 40px; // mimic newscenter
        margin: 0 1rem 0 0;
        width: auto;
        filter: invert(1);
        max-height: 4rem;
    }
    .navbar-end {
        justify-content: flex-end;
        display: flex;
    }
}
@media (min-width: $tablet) {
    .navbar.is-dark {
        img {
            height: 60px; // mimic newscenter
        }
    }
}
</style>
