<template>
    <div v-if="engagement">
        <div class="card is-radiusless mb-5">
            <div class="card-content">
                <div class="content">
                    <h4 class="level is-mobile is-size-3">
                        <span class="level-left">{{ engagement.name }}</span>
                        <span class="level-right mitreTheme">
                            <a class="mr-3 mt-1" role="button" aria-label="Toggle engagement details" v-on:click="toggleAccordion(engagement.number)">
                                <mdicon v-if="!accordions[engagement.number]" class="level-item item-icon mx-3 linkText" size="32" name="chevron-down" title="Expand engagement details" />
                                <mdicon v-else class="level-item item-icon mx-3 linkText" size="32" name="chevron-up" title="Collapse engagement details" />
                            </a>
                        </span>
                    </h4>
                    <p v-if="engagement.description.length" class="is-size-6 mr-6">
                        {{ engagement.description }}
                    </p>
                    <ul v-if="accordions[engagement.number]" class="mt-3 pl-0">
                        <li v-for="tool in engagement.tools" :key="tool.name" class="toolCategory mb-5 mr-6">
                            <span class="is-size-5 level-left">
                                <img v-if="toolsMap[tool && tool.name.toLowerCase()] && toolsMap[tool && tool.name.toLowerCase()].isImage" :src="getToolImage(tool.name)" class="toolIcon">
                                <mdicon v-else :name="getToolIcon(tool.name)" :class="tool.name === 'Box' ? 'titleSecondaryText' : ''" :size="22" title="Launch Tool" /> {{ tool.name }} Workspace
                            </span>
                            <ul class="toolsList mb-4 mx-4">
                                <li v-for="channel in tool.workspace" :key="channel.title" class="mb-3">
                                    <a v-if="channel.url" :href="channel.url" class="level is-mobile is-size-5" target="_blank" rel="noopener noreferrer">
                                        <span class="level-left">{{ channel.title }}</span>
                                        <span class="icon is-large level-right has-text-weight-normal is-relative">
                                            <mdicon name="open-in-new" title="Open Workspace" size="20" />
                                            <span class="is-size-7 is-overlay">Launch</span>
                                        </span>
                                    </a>
                                    <!-- protect against records with empty url -->
                                    <p v-else class="level is-mobile">
                                        <span class="level-left">{{ channel.title }}</span>
                                        <span class="icon is-large level-right">&nbsp;</span>
                                    </p>
                                    <p>{{ channel.description }}</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, toRefs, watchEffect } from 'vue'; // eslint-disable-line object-curly-newline
import * as slackImage from '../../assets/slack.png'; // eslint-disable-line no-unused-vars
import * as teamsImage from '../../assets/teams.jpg'; // eslint-disable-line no-unused-vars

export default {
    name: 'engagement-item',
    components: {
    },
    props: {
        engagement: {
            default: () => ({}),
            type: Object,
        },
        firstEngagementId: {
            default: '',
            type: String,
        },
        isAllAccordionsOpen: {
            default: false,
            type: Boolean,
        },
    },
    setup(props) {
        const { firstEngagementId, engagement, isAllAccordionsOpen } = toRefs(props);

        const toolsMap = {
            box: { type: 'box', icon: 'cloud', isImage: false },
            slack: { type: 'slack', icon: slackImage, isImage: true },
            handshake: { type: 'handshake', icon: 'cloud-outline', isImage: false },
            teams: { type: 'teams', icon: teamsImage, isImage: true },
            website: { type: 'website', icon: 'web', isImage: false },
            fallback: { type: 'fallback', icon: 'tools', isImage: false },  // handle undefined workspace tools
        };

        const getToolImage = (name) => {
            const { icon } = toolsMap[name.toLowerCase()];
            return icon;
        };

        const getToolIcon = (name) => toolsMap[name.toLowerCase()]?.icon || toolsMap.fallback.icon;

        const accordions = ref({ });
        const toggleAccordion = (engagementId) => {
            if (Object.hasOwn(accordions.value, engagementId)) {
                accordions.value[engagementId] = !accordions.value[engagementId];
            } else {
                // add to the accordion map if not already there
                accordions.value[engagementId] = true;
            }
        };

        watchEffect(() => {
            // watch for parent click to open/close all accordions
            if (isAllAccordionsOpen.value && engagement.value?.number) {
                accordions.value[engagement.value.number] = true;
            } else {
                // close all but leave the first engagement open
                accordions.value[engagement.value.number] = false;
                accordions.value[firstEngagementId.value] = true;
            }
        });

        return {
            toolsMap,
            getToolImage,
            getToolIcon,
            accordions,
            toggleAccordion,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
    box-shadow: none;
    border: 1px solid $mitreSilver;
}
.content {
    p:not(:last-child) {
        margin-bottom: 2px;
    }

    p.title {
        margin-bottom: .8rem;
    }
}
// toggle hidden tool area
ul {
    padding-left: 0;
}
ul:last-of-type {
    margin-bottom: 0;
}
li.toolCategory {
    border-bottom: solid 1px $mitreSilver;
}
li.toolCategory:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
}
.toolsList {
    list-style-type: none;
    margin-right: 0.5rem;
}
.level:not(:last-child) {
    margin-bottom: 0;
}
.level-left {
    flex-shrink: 1;     // allow long text to wrap
}
.toolIcon {
    width: 1.3em;
    height: auto;
    margin-right: .5em;
}
.toolCategory {
    .mdi {
        margin-right: 0.3rem;
    }
}

.is-overlay {
    top: 2.2rem;
    left: 0.75rem;
}


// larger browser width breakpoints
@include desktop {
    .toolsList {
        margin-right: 0;
    }
}
</style>
