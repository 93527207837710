import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import axios from 'axios';

function isMitreDomain(url) {
    try {
        const urlHost = new URL(url).hostname;
        return urlHost.indexOf('.mitre.org') > -1;
    } catch (err) {
        // Relative URLs will throw an error when used with new URL() so setting to true.
        return true;
    }
}

export default class Api {
    // Okta authentication config
    static setupAxiosAuthInterceptor(authInstance) {
        // Automatically pass the user's Okta access token as an Authorization header in each API request
        axios.interceptors.request.use((config) => {
            /*
             * Can filter out some urls here if they don't support Okta Bearer tokens
             */
            if (isMitreDomain(config.url)) {
                // 3.0.0 sample code (older example from Vue 2 using $auth.getAccessToken)
                // https://github.com/okta/samples-js-vue/blob/3187d5ffa50bbc8a02653343a169fafb028a4167/okta-hosted-login/src/components/Messages.vue#L90
                const accessToken = authInstance.getAccessToken();
                if (accessToken) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
            }
            return config;
        });
    }

    // get Salesforce account provisioning data
    static getAccountResourcesForUser(email) {
        // using Mulesoft endpoint for now
        // https://mulesoftdev1.mitre.org:4447/getworkspace?user=gforbes@mitre.org
        return axios.get(`${window.envConfig.mulesoftSalesforceUrl}/getworkspace?user=${email}`);
    }

    // partner products API
    static getProductsForProject(engagementId) {
        // using Mulesoft endpoint for now
        // https://mulesoftdev1.mitre.org:4448/getmpldocuments?project_no=0215CY05-J5
        return axios.get(`${window.envConfig.mulesoftProductsUrl}/getmpldocuments?project_no=${engagementId}`);
    }
}
